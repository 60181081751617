import axios from '@/libs/axios'

class StudyRoom {
  static view (params) {
    return axios.post('user/study-room', params)
  }

  static viewContent (params) {
    return axios.post('user/study-room/content', params)
  }

  static updateContent (params) {
    return axios.post('user/study-room/content/update', params)
  }

  static viewWriting (params) {
    return axios.post('user/study-room/writing', params)
  }

  static submitWriting (params) {
    return axios.post('user/study-room/writing/submit', params)
  }

  static contentIsDone (params) {
    return axios.post('user/study-room/content/done', params)
  }

  static contentTranslationReset (params) {
    return axios.post('user/study-room/content/translation/reset', params)
  }

  static uploadRecording (params) {
    return axios.post('user/study-room/content/upload-recording', params, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }

  static enroll (params) {
    return axios.post('user/study-room/enroll', params)
  }

  static getLearningResult (params) {
    return axios.post('user/study-room/get-learning-results', params)
  }

  static postpone (id) {
    return axios.post('user/study-room/postpone', { id })
  }

  static demoGetLearningResult (params) {
    return axios.post('demo/user/study-room/get-learning-results', params)
  }

  static demoView (params) {
    return axios.post('demo/user/study-room', params)
  }

  static demoPostpone (id) {
    return axios.post('demo/user/study-room/postpone', { id, demo: true })
  }

  static demoContentIsDone (params) {
    return axios.post('demo/user/study-room/content/done', params)
  }

  static demoViewContent (params) {
    return axios.post('demo/user/study-room/content', params)
  }

  static demoContentTranslationReset (params) {
    return axios.post('demo/user/study-room/content/translation/reset', params)
  }

  static demoUpdateContent (params) {
    return axios.post('demo/user/study-room/content/update', params)
  }

  static demoUploadRecording (params) {
    return axios.post('demo/user/study-room/content/upload-recording', params, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }
}

export default StudyRoom
